<template>
     <section class="page_title big  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <div class="content_box">
                           <h6>Recent from the Webinars and Conferences</h6>
                           <h1>  Latest Project Webinars and Conferences</h1>
                           <ul class="bread_crumb text-center">
                              <li class="bread_crumb-item"><a href="#">Home</a></li>
                              <li class="bread_crumb-item"><a href="#">Products</a></li>
                              <li class="bread_crumb-item active">Webinars and Conferences</li>
                           </ul>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
            <!-----------page_title-end-------------->
            <!-----------blog_v1--------------->
            <section class="blog_v1 pdt_80  pdb_100">
               <div class="container">
                  <div class="featured_post">
                     <div class="row">
                        <!---------row----------->
                        <div class="col-lg-12">
                           <!---------blog_box----------->
                           <div class="blog_box type_two">
                              <div class="image_box">
                                 <img src="@/assets/image/products/jMuseveni.jpg" class="img-fluid" alt="img" />
                                 <div class="overlay ">
                                    <a href="@/assets/image/products/jMuseveni.jpg" data-fancybox="gallery " data-caption=" ">
                                    <span class="linearicons-picture2"></span>
                                    </a>
                                 </div>
                              </div>
                              <div class="content_box clearfix">
                                 <h2><a href="#">Release of the Calender for Re-Opening of Schools</a></h2>
                                 <ul>
                                    <li><span class="linearicons-calendar-full"></span>15 DEC 2021</li>
                                    <li><span class="linearicons-user"></span>Fst Lady Hon.Janek K Museveni</li>
                                 </ul>
                                 <p>There was a set of Guidelines that governed the reopening of tertiary institutions. We also have completed
                                    the development of the set of Guidelines to inform the
                                    re-opening of the Basic and Secondary levels of the
                                    Education system.</p>
                                 <a target="_blank" href="/assets/image/products/PRESS STATEMENT ON REOPENING OF SCHOOLS WITHOUT THE POINTS IN MAAMA'S CLOSING STATEMENT.pdf" class="read_more tp_one"> Download<span class="linearicons-arrow-down"></span></a>
                              </div>
                           </div>
                           <!---------blog_box end----------->
                        </div>
                        <!---------row-end---------->
                     </div>
                  </div>




                  <div class="row">
                  <div class="col-lg-4">
                        <!---------blog_box----------->
                        <div class="blog_box type_one">
                           <div class="image_box">
                              <img src="@/assets/image/products/costerrica.jpg" class="img-fluid" alt="img" />
                              <div class="overlay">
                                 <a href="@/assets/image/products/costerrica.jpg" data-fancybox="gallery " data-caption=" ">
                                    <span class="linearicons-picture2"></span>
                                 </a>
                              </div>
                           </div>
                           <div class="content_box">
                              <h2><a href="#">Lessons in COVID and health systems resilience from Thailand, Costa Rica, and Uganda</a></h2>
                              
                              <p>In a #PMAC2022 side event, a discussion on health systems resilience reveals lessons in fighting COVID-19 now and beating future pandemics, later</p>
                              <a target="_blank" href="https://www.exemplars.health/stories/lessons-in-covid-and-health-systems-resilience-from-thailand?utm_source=newsletter&utm_medium=email&utm_campaign=2022012510-prince_mahidol_award-nb-ne-em-gen-carrot_stick-na&_cldee=cm5kZWpqb0BtdXNwaC5hYy51Zw%3d%3d&recipientid=contact-f6fb57d13125eb11a8130022481c6b49-12152948e83d445d93e0ab0a5192915b&esid=8fd343f9-fb7d-ec11-8d21-000d3a4ee0b2" class="read_more tp_one">Read More<span class="linearicons-arrow-right"></span></a>
                           </div>
                        </div>
                        <!---------blog_box end----------->
                     </div>
                 
                     <!----------row-end---------->
                  </div>
                  
            
               </div>
            </section>
            <!-----------blog_v1-end-------------->
       
</template>